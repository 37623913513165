<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { accessToRecords } from '@/api/menu';
import { getToken } from '@/utils/auth'
export default  {
  name:  'App',
  created(){
    this.$router.afterEach(to => {
      if(getToken()) {
        accessToRecords({
          menuPath: this.$route.path
        }).then(res => {
          console.log('accessToRecords')
        })
      }
    })
  }
}
</script>
